import React from "react"
import styled from "styled-components"
import theme from "../theme"
import Headline from "./headline"
import Text from "./text"
import Svg from "react-inlinesvg"
import { SvgFiles } from "../hooks/svg-files"

const LogoGridContainer = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  max-width: ${theme.layout.s.width}vw;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${theme.layout.m.width}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${theme.layout.l.width}vw;
  }
`

const LogoGridContainerInner = styled.div``

const LogoGridText = styled.div`
  max-width: ${theme.layout.s.maxWidth * 100}vw;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${10 * theme.layout.m.grid * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${10 * theme.layout.l.grid * 100}vw;
  }
`

const LogoGridContainerElements = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: ${theme.layout.s.gutter * 100 * 2}vw 0;

  @media (min-width: ${theme.breakpoint.medium}) {
    margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
    max-width: ${theme.layout.m.width}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
    max-width: ${12 * theme.layout.l.grid * 100}vw;
  }
`

const LogoGridElement = styled.div`
  padding: ${theme.layout.s.gutter * 100}vw;
  width: ${2 * theme.layout.s.grid * 100}vw;
  height: ${2 * theme.layout.s.grid * 100}vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (min-width: ${theme.breakpoint.medium}) {
    width: ${2 * theme.layout.m.grid * 100}vw;
    height: ${2 * theme.layout.m.grid * 100}vw;
    padding: ${theme.layout.m.gutter * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    width: ${2 * theme.layout.l.grid * 100}vw;
    height: ${2 * theme.layout.l.grid * 100}vw;
    padding: ${theme.layout.l.gutter * 100}vw;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
    background-color: hsla(0, 0%, 0%, 0.01);
  }
`

const LogoGridElements = (props) => {
  const data = SvgFiles()

  return props.elements.map((value, key) => {
    return data.allFile.nodes.map((file, k) => {
      if (file.base === value.icon) {
        return (
          <LogoGridElement key={key}>
            {value.link ? (
              <a href={value.link} target="_blank" rel="noopener noreferrer">
                <Svg src={file.publicURL} title={value.title} />
              </a>
            ) : (
              <Svg src={file.publicURL} title={value.title} />
            )}
          </LogoGridElement>
        )
      } else {
        return null
      }
    })
  })
}

const LogoGrid = (props) => {
  return (
    <LogoGridContainer data-bg={props.bg} id={props.id}>
      <LogoGridContainerInner>
        <Headline {...props} />
        <LogoGridContainerElements>
          <LogoGridElements elements={props.logos} />
        </LogoGridContainerElements>
        {props.text ? (
          <LogoGridText>
            <Text>{props.text}</Text>
          </LogoGridText>
        ) : (
          ""
        )}
      </LogoGridContainerInner>
    </LogoGridContainer>
  )
}

export default LogoGrid
