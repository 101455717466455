import { useStaticQuery, graphql } from "gatsby"
export const SvgFiles = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { eq: "svg" } }) {
          nodes {
            publicURL
            base
          }
        }
      }
    `
  )
  return data
}
