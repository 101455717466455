import React from "react"
import styled from "styled-components"
import IntroSvg from "../assets/signet.svg"
import theme from "../theme"

const IntroContainer = styled.section`
  min-height: calc(100vh - 6rem);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: ${theme.layout.s.width}vw;
  margin: 0 auto;
  padding: 0 ${theme.layout.s.gutter * 100}vw;

  @media (min-width: ${theme.breakpoint.medium}) {
  }

  @media (min-width: ${theme.breakpoint.large}) {
    min-height: 100vh;
  }

  svg {
    height: auto;
    max-height: auto;
    max-width: 100%;
    display: block;

    width: ${6 * theme.layout.s.grid * 100 - 2 * theme.layout.s.gutter * 100}vw;
    height: ${2 * theme.layout.s.grid * 100 -
    2 * theme.layout.s.gutter * 100}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${24 * theme.layout.m.grid * 100 -
      2 * theme.layout.m.gutter * 100}vw;
      height: ${2 * theme.layout.m.grid * 100 -
      2 * theme.layout.m.gutter * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      width: ${24 * theme.layout.l.grid * 100 -
      2 * theme.layout.l.gutter * 100}vw;
      height: ${2 * theme.layout.l.grid * 100 -
      2 * theme.layout.l.gutter * 100}vw;
    }
  }
`

const IntroContainerInner = styled.div`
  width: ${theme.layout.s.width}vw;
  position: relative;
  margin: ${theme.layout.s.gutter * 100 * 2}vw 0;
  width: 100%;

  @media (min-width: ${theme.breakpoint.medium}) {
    margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
  }
`

const Intro = (props) => {
  return (
    <IntroContainer data-bg={props.bg} id={props.id}>
      <IntroContainerInner>
        <IntroSvg />
      </IntroContainerInner>
    </IntroContainer>
  )
}

export default Intro
