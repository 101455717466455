import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import theme from "../theme"
import Headline from "./headline"
import Text from "./text"
import Img from "gatsby-image"
import { Link } from "gatsby"
import * as Scroll from "react-scroll"

const ProjectHighligtsContainer = styled.section`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  max-width: ${theme.layout.s.width}vw;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${theme.layout.m.width}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${theme.layout.l.width}vw;
  }

  .gatsby-image-wrapper {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  }

  .image {
    transition: order 0.5s linear;
    padding: ${theme.layout.s.gutter * 100}vw;
    height: ${2 * theme.layout.s.grid * 100}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      height: ${2 * theme.layout.m.grid * 100}vw;
      padding: ${theme.layout.m.gutter * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      height: ${2 * theme.layout.l.grid * 100}vw;
      padding: ${theme.layout.l.gutter * 100}vw;
    }

    &:nth-child(1) {
      order: 1;
    }

    &:nth-child(2) {
      order: 2;

      @media (min-width: ${theme.breakpoint.medium}) {
        order: 3;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 2;
      }
    }

    &:nth-child(3) {
      order: 3;

      @media (min-width: ${theme.breakpoint.medium}) {
        order: 2;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 3;
      }
    }

    &:nth-child(4) {
      order: 5;

      @media (min-width: ${theme.breakpoint.medium}) {
        order: 7;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 4;
      }
    }

    &:nth-child(5) {
      order: 4;

      @media (min-width: ${theme.breakpoint.medium}) {
        order: 7;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 5;
      }
    }

    &:nth-child(6) {
      order: 6;

      @media (min-width: ${theme.breakpoint.large}) {
        order: 4;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 6;
      }
    }

    &:nth-child(7) {
      order: 8;

      @media (min-width: ${theme.breakpoint.medium}) {
        order: 5;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 7;
      }
    }

    &:nth-child(8) {
      order: 9;

      @media (min-width: ${theme.breakpoint.large}) {
        order: 8;
      }
    }

    &:nth-child(9) {
      order: 7;

      @media (min-width: ${theme.breakpoint.medium}) {
        order: 8;
      }

      @media (min-width: ${theme.breakpoint.large}) {
        order: 9;
      }
    }
  }

  .image > div {
    height: 100%;
  }

  .ipad {
    width: ${4 * theme.layout.s.grid * 100}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${4 * theme.layout.m.grid * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      width: ${4 * theme.layout.l.grid * 100}vw;
    }
  }

  .macbook {
    width: ${6 * theme.layout.s.grid * 100}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${10 * theme.layout.m.grid * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      width: ${10 * theme.layout.l.grid * 100}vw;
    }
  }

  .iphone {
    width: ${2 * theme.layout.s.grid * 100}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${2 * theme.layout.m.grid * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      width: ${2 * theme.layout.l.grid * 100}vw;
    }
  }
`
const ProjectHighligtsElements = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;

  max-width: ${theme.layout.s.width}vw;
  margin: ${theme.layout.s.gutter * 100 * 2}vw 0;

  @media (min-width: ${theme.breakpoint.medium}) {
    margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
    max-width: ${theme.layout.m.width}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
    max-width: ${theme.layout.l.maxWidth}vw;
  }
`

const ProjectHighligtsContainerInner = styled.div``

const ProjectHighligtsText = styled.div`
  max-width: ${theme.layout.s.maxWidth * 100}vw;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${10 * theme.layout.m.grid * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${10 * theme.layout.l.grid * 100}vw;
  }
`

const LinkImage = (props) => {
  const LinkImageUrl = (e) => {
    let scroll = Scroll.animateScroll
    if (typeof document !== "undefined") {
      const projectFullElement = document.querySelector(
        `#${props.project.frontmatter.key}`
      )

      if (projectFullElement) {
        e.preventDefault()

        if (typeof window !== "undefined") {
          // eslint-disable-next-line global-require
          scroll.scrollTo(projectFullElement.offsetTop)
        }
      }
    }
  }

  return (
    <Link
      className={"image " + props.type}
      to={props.project.fields.slug}
      onClick={(e) => {
        LinkImageUrl(e)
      }}
    >
      {props.children}
    </Link>
  )
}

const ProjectTeaser = (props) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark {
        edges {
          node {
            fields {
              slug
            }
            fileAbsolutePath
            frontmatter {
              key
              title
              image_iphone {
                childImageSharp {
                  fluid(maxWidth: 420, maxHeight: 720, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image_ipad {
                childImageSharp {
                  fluid(maxWidth: 900, maxHeight: 720, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              image_macbook {
                childImageSharp {
                  fluid(maxWidth: 2340, maxHeight: 720, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filter = (key) => {
    const project = data.allMarkdownRemark.edges.find((edge) =>
      edge.node.frontmatter.key.match(key)
    )

    return project.node
  }

  const project = filter(props.projectKey)

  const outputImage = () => {
    if (props.type === "macbook") {
      return (
        <Img
          fluid={project.frontmatter.image_macbook.childImageSharp.fluid}
          width={
            project.frontmatter.image_macbook.childImageSharp.presentationWidth
          }
          height={
            project.frontmatter.image_macbook.childImageSharp.presentationHeight
          }
          imgStyle={{ objectPosition: "center top" }}
          alt={project.frontmatter.title}
          title={project.frontmatter.title}
        />
      )
    }

    if (props.type === "ipad") {
      return (
        <Img
          fluid={project.frontmatter.image_ipad.childImageSharp.fluid}
          width={
            project.frontmatter.image_ipad.childImageSharp.presentationWidth
          }
          height={
            project.frontmatter.image_ipad.childImageSharp.presentationHeight
          }
          imgStyle={{ objectPosition: "center top" }}
          alt={project.frontmatter.title}
          title={project.frontmatter.title}
        />
      )
    }

    if (props.type === "iphone") {
      return (
        <Img
          fluid={project.frontmatter.image_iphone.childImageSharp.fluid}
          width={
            project.frontmatter.image_iphone.childImageSharp.presentationWidth
          }
          height={
            project.frontmatter.image_iphone.childImageSharp.presentationHeight
          }
          imgStyle={{ objectPosition: "center top" }}
          alt={project.frontmatter.title}
          title={project.frontmatter.title}
        />
      )
    }

    return ""
  }

  return (
    <LinkImage project={project} type={props.type}>
      {outputImage()}
    </LinkImage>
  )
}

const ProjectsHighligts = (props) => {
  return (
    <ProjectHighligtsContainer data-bg={props.bg} id={props.id}>
      <ProjectHighligtsContainerInner>
        <Headline {...props} />
        <ProjectHighligtsElements>
          <ProjectTeaser
            projectKey={props.projects.macbook[0]}
            type="macbook"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.ipad[0]}
            type="ipad"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.iphone[0]}
            type="iphone"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.iphone[1]}
            type="iphone"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.macbook[1]}
            type="macbook"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.ipad[1]}
            type="ipad"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.ipad[2]}
            type="ipad"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.iphone[2]}
            type="iphone"
          ></ProjectTeaser>
          <ProjectTeaser
            projectKey={props.projects.macbook[2]}
            type="macbook"
          ></ProjectTeaser>
        </ProjectHighligtsElements>
        {props.children && (
          <ProjectHighligtsText>
            <Text>{props.children}</Text>
          </ProjectHighligtsText>
        )}
      </ProjectHighligtsContainerInner>
    </ProjectHighligtsContainer>
  )
}

export default ProjectsHighligts
