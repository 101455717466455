import React from "react"
import styled from "styled-components"
import MapSvg from "../assets/map.svg"
import theme from "../theme"
import Text from "./text"
import Headline from "./headline"

const MapContainer = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: ${theme.layout.s.width}vw;
  margin: 0 auto;
  padding: 0 ${theme.layout.s.gutter * 100}vw;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${theme.layout.m.grid * 10 * 100}vw;
    padding: 0 ${theme.layout.m.gutter * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${theme.layout.l.grid * 10 * 100}vw;
    padding: 0 ${theme.layout.l.gutter * 100}vw;
  }

  svg {
    height: auto;
    max-height: auto;
    max-width: 100%;
    margin: ${theme.layout.s.gutter * 100 * 4}vw 0;
    display: block;

    @media (min-width: ${theme.breakpoint.medium}) {
      margin: ${theme.layout.m.gutter * 100 * 4}vw 0;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      margin: ${theme.layout.l.gutter * 100 * 4}vw 0;
    }
  }
`

const MapContainerInner = styled.div`
  width: ${theme.layout.s.width}vw;
  position: relative;
  margin: ${theme.layout.s.gutter * 100 * 2}vw 0;
  width: 100%;

  @media (min-width: ${theme.breakpoint.medium}) {
    margin: ${theme.layout.m.gutter * 100 * 2}vw 0;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    margin: ${theme.layout.l.gutter * 100 * 2}vw 0;
  }
`

const Map = (props) => {
  return (
    <MapContainer data-bg={props.bg} id={props.id}>
      <MapContainerInner>
        <Headline {...props} />
        <MapSvg />
        <Text>{props.children}</Text>
      </MapContainerInner>
    </MapContainer>
  )
}

export default Map
