import React from "react"
import styled from "styled-components"
import theme from "../theme"
import Headline from "./headline"
import Masonry from "react-masonry-component"
import Text from "./text"
import Svg from "react-inlinesvg"
import { SvgFiles } from "../hooks/svg-files"

const LogoGridMasonryContainer = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;

  .gallery {
    width: ${theme.layout.s.width}vw;
    max-width: ${theme.layout.s.width}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${theme.layout.m.width}vw;
      max-width: ${theme.layout.m.width}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      width: ${theme.layout.l.maxWidth}vw;
      max-width: ${theme.layout.l.maxWidth}vw;
    }
  }
`

const LogoGridMasonryElement = styled.div`
  padding: ${theme.layout.s.gutter * 100}vw;
  width: ${theme.layout.s.grid * 100}vw;
  height: ${theme.layout.s.grid * 100}vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media (min-width: ${theme.breakpoint.medium}) {
    padding: ${theme.layout.m.gutter * 100}vw;
    width: ${2 * theme.layout.m.grid * 100}vw;
    height: ${2 * theme.layout.m.grid * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    padding: ${theme.layout.l.gutter * 100}vw;
    width: ${2 * theme.layout.l.grid * 100}vw;
    height: ${2 * theme.layout.l.grid * 100}vw;
  }

  &.large {
    width: ${2 * theme.layout.s.grid * 100}vw;
    height: ${2 * theme.layout.s.grid * 100}vw;

    @media (min-width: ${theme.breakpoint.medium}) {
      width: ${4 * theme.layout.m.grid * 100}vw;
      height: ${4 * theme.layout.m.grid * 100}vw;
    }

    @media (min-width: ${theme.breakpoint.large}) {
      width: ${4 * theme.layout.l.grid * 100}vw;
      height: ${4 * theme.layout.l.grid * 100}vw;
    }
  }

  svg {
    width: 100%;
    max-height: 100%;
    height: auto;
    background-color: hsla(0, 0%, 0%, 0.01);
  }
`

const LogoGridMasonryContainerElements = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LogoGridText = styled.div`
  max-width: ${theme.layout.s.maxWidth * 100}vw;
  margin: 0 auto;

  @media (min-width: ${theme.breakpoint.medium}) {
    max-width: ${10 * theme.layout.m.grid * 100}vw;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    max-width: ${10 * theme.layout.l.grid * 100}vw;
  }
`

const LogoGridMasonryElements = (props) => {
  const data = SvgFiles()

  return props.elements.map((value, key) => {
    return data.allFile.nodes.map((file, k) => {
      if (file.base === value.icon) {
        const color = theme.color[props.color]

        return (
          <LogoGridMasonryElement
            color={color}
            className={value.layout ? value.layout : ""}
            key={key}
          >
            <Svg src={file.publicURL} title={value.title} />
          </LogoGridMasonryElement>
        )
      } else {
        return null
      }
    })
  })
}

const LogoGridMasonry = (props) => {
  return (
    <LogoGridMasonryContainer data-bg={props.bg} id={props.id}>
      <Headline {...props} />
      <LogoGridMasonryContainerElements>
        <Masonry
          elementType={"div"}
          updateOnEachImageLoad={true}
          className="gallery"
        >
          <LogoGridMasonryElements elements={props.logos} color={props.bg} />
        </Masonry>
      </LogoGridMasonryContainerElements>{" "}
      {props.text && (
        <LogoGridText>
          <Text>{props.text}</Text>
        </LogoGridText>
      )}
    </LogoGridMasonryContainer>
  )
}

export default LogoGridMasonry
